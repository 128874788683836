import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";


// component to render footer
const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer className="py-3">
            <Container>
                <div className='landing-footer-wrapper'>
                    <div className='privacy-terms-links'>
                        <Link to="/privacy-policy"><strong>{t("Privacy Policy")}</strong></Link>  •  <Link to="/terms-and-conditions"><strong>{t("Terms and Conditions")}</strong></Link>
                    </div>
                    <div className='copyright-notice'>
                        {t("Copyright © Skynettechnologies.com")}
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer