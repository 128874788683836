import { useTranslation } from "react-i18next";
import useWindowSize from '../../Helpers/useWindowSize';
import { useEffect, useState } from "react";


// component to render loader
const CustomLoader = () => {

    const { t } = useTranslation();

    const { windowHeight } = useWindowSize();

    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (windowHeight) {
            const temp = (windowHeight * .44) / 480;
            setScale(temp);
        }
    }, [windowHeight])

    return (
        <>
            <div className="cat-container">
                <div className="all-wrap">
                    <div className="scale-wrapper" style={{ transform: `scale(${scale})` }}>
                        <div className="all">
                            <div className="yarn"></div>
                            <div className="cat-wrap">
                                <div className="cat">
                                    <div className="cat-upper">
                                        <div className="cat-leg"></div>
                                        <div className="cat-leg"></div>
                                        <div className="cat-head">
                                            <div className="cat-ears">
                                                <div className="cat-ear"></div>
                                                <div className="cat-ear"></div>
                                            </div>
                                            <div className="cat-face">
                                                <div className="cat-eyes"></div>
                                                <div className="cat-mouth"></div>
                                                <div className="cat-whiskers"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cat-lower-wrap">
                                        <div className="cat-lower">
                                            <div className="cat-leg">
                                                <div className="cat-leg">
                                                    <div className="cat-leg">
                                                        <div className="cat-leg">
                                                            <div className="cat-leg">
                                                                <div className="cat-leg">
                                                                    <div className="cat-leg">
                                                                        <div className="cat-leg">
                                                                            <div className="cat-leg">
                                                                                <div className="cat-leg">
                                                                                    <div className="cat-leg">
                                                                                        <div className="cat-leg">
                                                                                            <div className="cat-leg">
                                                                                                <div className="cat-leg">
                                                                                                    <div className="cat-leg">
                                                                                                        <div className="cat-leg">
                                                                                                            <div className="cat-paw"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cat-leg">
                                                <div className="cat-leg">
                                                    <div className="cat-leg">
                                                        <div className="cat-leg">
                                                            <div className="cat-leg">
                                                                <div className="cat-leg">
                                                                    <div className="cat-leg">
                                                                        <div className="cat-leg">
                                                                            <div className="cat-leg">
                                                                                <div className="cat-leg">
                                                                                    <div className="cat-leg">
                                                                                        <div className="cat-leg">
                                                                                            <div className="cat-leg">
                                                                                                <div className="cat-leg">
                                                                                                    <div className="cat-leg">
                                                                                                        <div className="cat-leg">
                                                                                                            <div className="cat-paw"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cat-tail">
                                                <div className="cat-tail">
                                                    <div className="cat-tail">
                                                        <div className="cat-tail">
                                                            <div className="cat-tail">
                                                                <div className="cat-tail">
                                                                    <div className="cat-tail">
                                                                        <div className="cat-tail">
                                                                            <div className="cat-tail">
                                                                                <div className="cat-tail">
                                                                                    <div className="cat-tail">
                                                                                        <div className="cat-tail">
                                                                                            <div className="cat-tail">
                                                                                                <div className="cat-tail">
                                                                                                    <div className="cat-tail">
                                                                                                        <div className="cat-tail -end"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='fullscreen-loader-wrapper'>
                <div className='fullscreen-loader'>
                    <div className='loader-wrapper'>
                        <div className='loader-text'> <strong> {t("loader-text")} </strong> </div>
                        <div className="linear-progress">
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomLoader