import {
  LOADING,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  WEBSITE_URL,
  WEBSITE_SCANNING_PROCESS,
  WEBSITE_SCANNING_PROCESS_PER,
  WEBSITE_RESCANNING_PROCESS,
  WEBSITE_SCREENSHOT,
  WEBSITE_WIDGET,
  SET_PAID,
  SET_STANDARD,
  SET_LEVEL_FAIL_COUNT,
  SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET,
  SET_FORM_SUBMIT_FLAG,
  SET_LANGUAGE
} from "../Actions/Types";


// function to update redux state
const CombinedReducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_MESSAGE:
      return {
        ...state,
        toastMessage: payload
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        toastMessage: null
      }
    case WEBSITE_URL:
      return {
        ...state,
        websiteURL: payload
      }
    case WEBSITE_SCANNING_PROCESS:
      return {
        ...state,
        scaningProcess: payload
      }
    case WEBSITE_RESCANNING_PROCESS:
      return {
        ...state,
        reScaningProcess: payload
      }
    case WEBSITE_SCREENSHOT:
      return {
        ...state,
        screenShot: payload
      }
    case WEBSITE_WIDGET:
      return {
        ...state,
        isWidget: payload
      }
    case WEBSITE_SCANNING_PROCESS_PER:
      return {
        ...state,
        scaningProcessPer: payload
      }
    case SET_STANDARD:
      return {
        ...state,
        standard: payload
      }
    case SET_PAID:
      return {
        ...state,
        isPaidFree: payload
      }
    case SET_LEVEL_FAIL_COUNT:
      return {
        ...state,
        levelFailCounts: payload
      }
    case SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET:
      return {
        ...state,
        levelFailCountsFixedByWidget: payload
      }
    case SET_FORM_SUBMIT_FLAG:
      return {
        ...state,
        formSubmitFlag: payload
      }
    case SET_LANGUAGE:
      return {
        ...state,
        lang: payload
      }
    default:
      return state;
  }
};
export default CombinedReducer
