const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// api to start scanning process
const ScanningProcessAPI = async (
    websiteURL: string, // website URL
    is_first: number, // flag to get screenshot or scan results
    lang: string // language code
): Promise<any> => {

    const url = `${baseURL}/check-page-compliance-new`;

    const data = new FormData();

    data.append("url", websiteURL);
    data.append("is_first", is_first.toString());
    data.append("langcode", lang);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to get pdf report
const GetReportPDFAPI = async (
    id: number, // scanning id
    scoreImage: string, // base64 image of accessibility score
    wcagImage: string, // base64 image of wcag levels
    langcode: string // language code
): Promise<any> => {    

    const url = `${baseURL}/generate-compliance-report`;
    
    const data = new FormData();

    data.append("id", id.toString());
    data.append("langcode", langcode);
    data.append("score_image", scoreImage);
    data.append("level_image", wcagImage);


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to submit report a problem form
const ScanReportProblemAPI = async (
    firstName: string, // first name
    lastName: string, // last name
    email: string, // email address
    phone: string, // phone number 
    website: string, // website
    problem: string, // selected problem
    message: string, // user message
    selectedFile: File, // screenshot / video if any
): Promise<any> => {

    const url = `${baseURL}/compliance-report-problem`;
    
    const data = new FormData();

    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone", phone);
    data.append("url", website);
    data.append("problem_type", problem);
    data.append("description", message);
    data.append("screenshot_file", selectedFile);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { ScanningProcessAPI, ScanReportProblemAPI, GetReportPDFAPI }