import { Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { SET_STANDARD, WEBSITE_URL } from "../../Actions/Types";


// component to render user input form for scanning
const ScanURLFormComponents = () => {

    const { websiteURL, scaningProcess, scaningProcessPer } = useSelector((store: StoreProptypes) => store);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    const [websiteURLParam, setWebsiteURLParam] = useState("");

    const [userWebsiteURL, setUserWebsiteURL] = useState('');
    const [websiteURLValidation, setWebsiteURLValidation] = useState(false);
    const [websiteURLValidationMsg, setWebsiteURLValidationMsg] = useState('');


    useEffect(() => {
        if (websiteURL) {
            setUserWebsiteURL(websiteURL);
        }
    }, [websiteURL]);



    useEffect(() => {
        if (searchParams) {
            const temp = searchParams.get("website");
            if (temp) {
                setWebsiteURLParam(temp);
                setUserWebsiteURL(temp);
            }
        }
    }, [searchParams]);


    // function to validate website url
    const URLValidationEvent = (e: any) => {
        const inputValue = e.target.value;

        // check if input is empty
        if (inputValue === '') {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg(t("Please enter website URL."));
            setUserWebsiteURL(inputValue);
        } else {
            setWebsiteURLValidation(false);
            setWebsiteURLValidationMsg('')
            setUserWebsiteURL((inputValue.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')).trim('/'));
        }
    }

    // function to enter inp
    const enterInputValue = (e: any) => {
        const inputValue = e.target.value;

        if (inputValue === '' && websiteURL && websiteURL !== null) {
            setUserWebsiteURL(websiteURL);
            setWebsiteURLValidation(false);
            setWebsiteURLValidationMsg('')
        }
    }


    // function to handle form submit
    const HandleSubmit = (e: React.FormEvent) => {

        e.preventDefault();


        const httpRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

        // check if website url is empty
        if (userWebsiteURL === '') {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg(t("Please enter website URL."));
        }
        // check if website url is valid
        else if (!(httpRegex.test(userWebsiteURL))) {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg(t("Please enter valid website URL."));
        }
        else {
            if (websiteURLParam) {
                navigate('/');
            }
            dispatch({
                type: WEBSITE_URL,
                payload: userWebsiteURL,
            });
        }
    }


    // function to handle standard dropdown change
    const handleStandard = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;

        dispatch({
            type: SET_STANDARD,
            payload: value,
        });
    }

    return (
        <>
            <div className='scan-url-form mx-auto'>
                <Form onSubmit={(e) => HandleSubmit(e)}>
                    <div className='inline-form'>
                        <Form.Group controlId="website_url" className="mb-3 mb-md-0">
                            <Form.Control
                                className={`${websiteURLValidation ? `border-danger` : ``}`}
                                placeholder={t("Enter website URL")}
                                value={userWebsiteURL}
                                onChange={(e) => URLValidationEvent(e)}
                                onBlur={(e) => enterInputValue(e)}
                                disabled={scaningProcess}
                                aria-label="Website"
                            />
                            <Form.Text as='div' className="text-start">{t("For example: skynettechnologies.com")}</Form.Text>
                            {(scaningProcessPer && websiteURLValidation) ?
                                <div className="text-danger mb-3" role="alert">{t(websiteURLValidationMsg)}</div>
                                : <></>
                            }
                        </Form.Group>
                        <Form.Group controlId="accessibility_standard">
                            <Form.Select aria-label={t("select-accessibility-standard")} onChange={(e) => handleStandard(e)}>
                                <option value="WCAG 2.0, 2.1 & 2.2">WCAG 2.0, 2.1 & 2.2</option>
                                <option value="California Unruh">California Unruh</option>
                                <option value="Ontario AODA">Ontario AODA</option>
                                <option value="UK Equality Act">UK Equality Act</option>
                                <option value="European EN 301 549">European EN 301 549</option>
                                <option value="Israeli Standard 5568">Israeli Standard 5568</option>
                                <option value="Australian DDA">Australian DDA</option>
                                <option value="ADA Title lll">ADA Title lll</option>
                                <option value="ATAG 2.0">ATAG 2.0</option>
                                <option value="Section 508">Section 508</option>
                                <option value="Canada ACA">Canada ACA</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {(!scaningProcessPer && websiteURLValidation) ?
                        <div className="text-danger mb-3" role="alert">{t(websiteURLValidationMsg)}</div>
                        : <></>
                    }
                    <Button type="submit" size="lg" variant='primary' disabled={websiteURLValidation || scaningProcess}>
                        <span>{t("Start Scan")}</span>
                    </Button>

                </Form>

            </div>
        </>
    )
}

export default ScanURLFormComponents;