import { LOADING, SET_LEVEL_FAIL_COUNT, SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET, SET_PAID, WEBSITE_RESCANNING_PROCESS, WEBSITE_SCANNING_PROCESS, WEBSITE_SCANNING_PROCESS_PER, WEBSITE_SCREENSHOT, WEBSITE_URL, WEBSITE_WIDGET } from "../../Actions/Types";
import { GetReportPDFAPI, ScanningProcessAPI } from "../../Api/ScanningProcessAPI";

// service to handle scanning process api
const ScanningProcessService = (
    url: string, // website url
    is_first: number, // flag to fetch screenshot or scan result
    lang: string, // language code
    dispatch: Function, // dispatch function to store redux data
    setViolationsGroupList: Function, // function to set violation group list
    setErrorViolationsTotal: Function, // function to set violation error count
    setErrorViolationsTotalFixedByWidget: Function, // function to set violation errors that can be fixed by widget
    setSuccessViolationsTotal: Function, // function to set successful check total
    setAccessibilitySystemDetected: Function, // function to set detected accessibility system
    setRequestDataObj: Function, // function to set request data
    setScreenError: Function, // function to set error
    setApiRecall: Function, // function to set flag for api recall
) => {

    // check if flag is set to fetch scan results
    if (is_first === 2) {

        setRequestDataObj(null);

        dispatch({
            type: WEBSITE_SCREENSHOT,
            payload: null,
        });

        dispatch({
            type: WEBSITE_SCANNING_PROCESS,
            payload: true,
        });

        setAccessibilitySystemDetected('');
    }


    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to start scanning process
    ScanningProcessAPI(url, is_first, lang).then((response) => {

        // check if response is ok
        if (response && response.status !== 1) {

            // check if flag is set to fetch scan results
            if (is_first === 2) {
                ScanningProcessService(url, 1, lang, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
                setTimeout(() => {
                    ScanningProcessService(url, 0, lang, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
                }, 500)

            }
            // check if flag is set to fetch screenshot
            else if (is_first === 0) {
                dispatch({
                    type: WEBSITE_SCREENSHOT,
                    payload: response.page_image,
                });

            }
            else {
                // check if scanning process is completed
                if (response.completed_percentage === 100) {

                    // check if flag is received to recall api
                    if (response.reload === true) {
                        setApiRecall(true);
                    }
                    else {
                        

                        dispatch({
                            type: WEBSITE_SCANNING_PROCESS_PER,
                            payload: response.completed_percentage,
                        });
                        dispatch({
                            type: WEBSITE_SCANNING_PROCESS_PER,
                            payload: 100,
                        });

                        /* dispatch({
                            type: WEBSITE_WIDGET,
                            payload: response.accessibility_system === "AllInOneAccessibility",
                        }); */

                        dispatch({
                            type: SET_PAID,
                            payload: response.is_paid_free,
                        });

                        dispatch({
                            type: SET_LEVEL_FAIL_COUNT,
                            payload: response.level_fail_counts,
                        });

                        dispatch({
                            type: SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET,
                            payload: response.level_fail_counts_fixed_by_widget,
                        });

                        setTimeout(() => {
                            dispatch({
                                type: WEBSITE_SCANNING_PROCESS,
                                payload: false,
                            });

                            dispatch({
                                type: WEBSITE_RESCANNING_PROCESS,
                                payload: true,
                            });

                            dispatch({
                                type: LOADING,
                                payload: false,
                            });
                        }, 1000);

                        setViolationsGroupList(response.group_data);
                        setErrorViolationsTotal(response.total_fail);
                        setErrorViolationsTotalFixedByWidget(response.total_fail_fixed_by_widget);
                        setSuccessViolationsTotal(response.total_success);


                        setAccessibilitySystemDetected(response.accessibility_system_detected);
                        setRequestDataObj(response.request_data);
                    }

                }
                // check if scanning process is not completed
                if (response.completed_percentage !== 100) {

                    dispatch({
                        type: WEBSITE_SCANNING_PROCESS_PER,
                        payload: response.completed_percentage,
                    });
                    setApiRecall(true);
                }
            }


        } else {
            setScreenError(true);
            setRequestDataObj(response.request_data);
            dispatch({
                type: WEBSITE_SCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_RESCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_URL,
                payload: null,
            });
            dispatch({
                type: WEBSITE_SCREENSHOT,
                payload: null,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        }

    });
};


// service to handle get pdf report api
const getReportPDFService = (
    id: number, // scan id
    setPDFURL: Function, // function to set pdf url
    scoreImage: string, // base64 string of accessibility score image
    wcagImage: string, // base64 string of WCAG compliance image
    langcode: string // language code
) => {
    // api to get pdf report
    GetReportPDFAPI(id, scoreImage, wcagImage, langcode).then(response => {
        if (response) {
            setPDFURL(response.report_url);
        }
    })
}

export { ScanningProcessService, getReportPDFService }