import { routesList } from "../../Routes";

// API to generate meta data
const metaDataApi = (
    path: string, // url
    image?: string // og or twitter image
) => {

    const metaData = routesList.filter(route => route.path === path).map(route => route.metaData)[0];

    if (metaData) {
        return {
            metaTitle: metaData.title,
            metaDescription: metaData.description,
            canonicalLinkURL: `${window.location.protocol}//${window.location.host}/${window.location.pathname}`,
            ogImage: `${image ? image : "https://source.unsplash.com/2ShvY8Lf6l0/800x599"}`,
            ogTitle: metaData.title,
            ogDescription: metaData.description,
            twitterTitle: metaData.title,
            twitterDescription: metaData.description,
            twitterImage: `${image ? image : "https://source.unsplash.com/2ShvY8Lf6l0/800x599"}`,
        }
    }
    else {
        return null
    }

}

export default metaDataApi;