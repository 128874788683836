import logo from "../../Assets/img/accessibility-checker-logo.svg";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";
import { Button, Container } from "react-bootstrap";
import ScanURLFormComponents from "../ScanURLForm";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";


// component to render header
const CustomHeader = () => {

    const { t } = useTranslation();

    const { websiteURL } = useSelector((store: StoreProptypes) => store);

    const [showForm, setShowForm] = useState(false);

    // function to reload page on click
    const handleClick = () => {
        window.location.reload();
    }

    return (
        <>
            <div className="header-wrapper">
                <Container>
                    <div className="logo-wrapper">
                        <a href="/" role="button" onClick={handleClick}>
                            <img src={logo} alt={t('logo-alt-text')} />
                        </a>
                    </div>
                    <div className="lang-formToggle-wrapper">
                        {websiteURL ? <Button className="me-3" onClick={() => setShowForm(!showForm)}>{t("Scan a New URL")}</Button> : <></>}
                        <LanguageSwitcher />
                    </div>
                </Container>
            </div>
            {showForm ? <div className="header-scan-form-wrapper">
                <Container>
                    <ScanURLFormComponents />
                </Container>
            </div>
                : <></>}
        </>
    )
}

export default CustomHeader