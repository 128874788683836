import { matchRoutes, useLocation } from "react-router-dom"
import { routesList } from "../Routes";

// react hook to use current path
const useCurrentPath = () => {
    const location = useLocation()
    const route: any = matchRoutes(routesList, location);
    if(route){
        return route[0].pathname;
    }
    else return null    
}

export default useCurrentPath;