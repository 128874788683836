const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`

// API to get email address while downloading pdf report
const GetUserEmailAPI = async (
    id: number, // scan id
    email: string, // email address
    reason: string, // selected reason to use accessibility checker
    other: string, // user inputted reason to use accessibility checker
): Promise<any> => {

    const url = `${baseURL}/compliance-report-request`;

    const data = new FormData();

    data.append("id", id.toString());
    data.append("email", email);
    data.append("report_purpose", reason);
    data.append("report_purpose_other", other);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export default GetUserEmailAPI