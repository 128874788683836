import { Alert, Button, Modal, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import iconDownload from "../../Assets/img/icn-pdf.svg";
import { useEffect, useState } from "react";
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_SUBMIT_FLAG } from '../../Actions/Types';
import GetUserEmailAPI from '../../Api/GetUserEmailAPI';
import { requestDataProptype } from "../../PropTypes/ScanningScreenPropType";
import { getReportPDFService } from "../../Services/ScanningProcessService";

interface propTypes {
    requestDataObj: requestDataProptype, // scan object
    GetScreenshot: Function, // function to get screenshot
    scoreImage: string, // base64 image of score
    wcagImage: string // base64 image of wcag
}

// component to render download report form
const DownloadReport = (props: propTypes) => {

    const { t, i18n } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();

    const { requestDataObj, GetScreenshot, scoreImage, wcagImage } = props;

    const { formSubmitFlag } = useSelector((store: StoreProptypes) => store);

    const [formSubmitting, setFormSubmitting] = useState(false);

    const [scannerUserEmail, setScannerUserEmail] = useState('');

    const [scannerReason, setScannerReason] = useState('');

    const [scannerOtherReason, setScannerOtherReason] = useState('');

    const [scannerUserFormError, setScannerUserFormError] = useState('');

    const [pdfURL, setPDFURL] = useState('');
    const [pdfLoading, setPDFLoading] = useState(false);

    //const [apiCalled, setApiCalled] = useState(false);

    const tempLink = document.createElement("a");


    // function to handle download
    const Download = () => {
        setPDFLoading(true);
        //service to download pdf report
        getReportPDFService(requestDataObj.id, setPDFURL, scoreImage, wcagImage, i18n.language);
    }

    useEffect(() => {

        if (pdfURL) {
            tempLink.href = pdfURL;
            tempLink.setAttribute("download", "download");
            tempLink.setAttribute("target", "_blank");
            tempLink.classList.add("visually-hidden");

            document.body.appendChild(tempLink);
            setTimeout(() => {
                tempLink.click();
                setPDFLoading(false);
                //document.body.removeChild(tempLink);
            }, 300)
        }
    }, [pdfURL])


    useEffect(() => {
        GetScreenshot();
    }, [requestDataObj])


    // function to handle form submit
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setScannerUserFormError('');

        // check if user email is empty
        if (scannerUserEmail === '') {
            setScannerUserFormError(t('Please enter your email address'))
        }
        // check if user email is valid
        else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(scannerUserEmail) === false) {
            setScannerUserFormError(t('Please enter valid email address'));
        }
        // check if reason is not selected
        else if (scannerReason === '') {
            setScannerUserFormError(t('Please select the purpose for accessibility report'))
        }
        // check if other reason is not specified
        else if (scannerReason === '0' && scannerOtherReason === '') {
            setScannerUserFormError(t('Please enter your purpose for accessibility report'))
        }
        else {
            setFormSubmitting(true);
            // api to submit download report form
            GetUserEmailAPI(requestDataObj.id, scannerUserEmail, scannerReason, scannerOtherReason).then(response => {
                // check if response data is available
                if (response.Data) {
                    dispatch({
                        type: SET_FORM_SUBMIT_FLAG,
                        payload: true
                    });

                    setShowModal(false);
                    Download();
                }
                else {
                    setScannerUserFormError(t('Error submitting the form. Please try again later'));
                }
                setFormSubmitting(false);
            });
        }

    }


    // function to handle click on download report button
    const handleClick = () => {
        if (!formSubmitFlag) {
            setShowModal(true);
        }
        else {
            Download();
        }
    }


    return (
        <>
            <Button className="download-btn p-0" variant="link" onClick={handleClick} disabled={pdfLoading}>
                <span>
                    <img src={iconDownload} alt="" />
                </span>

                <span>{t("Download Free Accessibility Report")} </span>

                <span>
                    {pdfLoading ? <Spinner animation="border" size="sm" className="ms-1" /> : <></>}
                </span>

            </Button>
            
            <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} size="lg" centered>
                <Modal.Body>
                    <button className="modal-close-button" aria-label={t("Close Modal Popup")} onClick={() => setShowModal(false)}></button>
                    <div className="px-5 py-5">
                        {scannerUserFormError && (
                            <Alert onClose={() => setScannerUserFormError("")} variant='danger' dismissible>{scannerUserFormError}</Alert>
                        )}
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <Form.Group controlId="UserEmail" className='mb-3'>
                                <Form.Control aria-label={t('Email ID')} type="email" placeholder={t("Enter your Email ID")} value={scannerUserEmail} onChange={(e) => setScannerUserEmail(e.target.value)} required />
                            </Form.Group>
                            <Form.Group controlId="scannerUserReason" className='mb-3'>
                                <Form.Select aria-label={t('Purpose for Accessibility Report')} value={scannerReason} onChange={(e) => setScannerReason(e.target.value)} required>
                                    <option disabled value=""> {t("Select the purpose for accessibility report")}</option>
                                    <option value="1">{t("Addressing an accessibility-related lawsuit")}</option>
                                    <option value="2">{t("Making my website compliant with accessibility standards")}</option>
                                    <option value="3">{t("Testing my website for accessibility")}</option>
                                    <option value="4">{t("Fixing accessibility errors as a developer")}</option>
                                    <option value="5">{t("Using a widget and want to check compliance")}</option>
                                    <option value="0">{t("Other")}</option>
                                </Form.Select>
                            </Form.Group>
                            {scannerReason === "0" ?
                                <Form.Group controlId="OtherPurpose" className='mb-3'>
                                    <Form.Control aria-label={t('Other Purpose for Accessibility Report')} type="text" placeholder={t("Enter your purpose for accessibility report")} value={scannerOtherReason} onChange={(e) => setScannerOtherReason(e.target.value)} />
                                </Form.Group>
                                : <></>}

                            <div className='text-center mt-4'>
                                <Button disabled={formSubmitting} size='lg' variant="primary" type="submit" className='d-inline-flex align-items-center'>
                                    {formSubmitting ? <Spinner animation="grow" size="sm" className="me-2" /> : <></>}
                                    <span>
                                        {t("Submit")}
                                    </span>
                                </Button>
                            </div>
                            <p className='text-center mt-4'>{t("Email Popup Text")}</p>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default DownloadReport;