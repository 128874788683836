import { useSelector } from 'react-redux';
import screenMockupPlaceholder from '../../Assets/img/placeholder.svg';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { addDefaultImgSrc } from '../../Helpers/addDefaultImgSrc';
import { useEffect, useState } from 'react';

// component to render website screenshot
const WebsiteScreen = () => {

    const { screenShot, isLoading, scaningProcessPer } = useSelector((store: StoreProptypes) => store);

    const [blurValue, setBlurValue] = useState(2.5);

    useEffect(() => {
        // check if scanning progress is completed
        if (scaningProcessPer) {            
            if (scaningProcessPer === 100) {
                setBlurValue(0);
            } else {
                setBlurValue(blurValue - 0.2);
            }

        }
    }, [scaningProcessPer])

    
    return (
        <>
            <div className='screenshot-main-wrap h-100'>
                <div className='screenshot-main border h-100'>
                    <div className='screenshot-loader'>
                        <img onError={addDefaultImgSrc} src={screenShot ? screenShot : screenMockupPlaceholder} style={{ filter: `blur(${blurValue}px)` }} alt=""  />
                        {isLoading ? <span></span> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebsiteScreen;