import { useTranslation, Trans } from 'react-i18next';
import { groupDataMainPropTypes, groupDetailPropTypes } from '../../PropTypes/ScanningScreenPropType';
import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Offcanvas, Table, Nav, Container, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PassedIcon from "../../Assets/img/passed-icon.svg";
import FailedIcon from "../../Assets/img/failed-icon.svg";
import NAIcon from "../../Assets/img/not-applicable-icon.svg";
import LinkIcon from "../../Assets/img/link-icon.svg";
import alertIcon from "../../Assets/img/alert-icon.svg";
import logo from "../../Assets/img/accessibility-checker-logo.svg";

import DisabilitiesAffected from '../DisabilitiesAffected';
import ElementsListMain from './ElementsList';
import { useSelector } from 'react-redux';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';


interface propType {
    violationsGroupList: groupDataMainPropTypes, // violation group list
    fixedByWidget: boolean // flag to display results with widget fixes
}


interface levelCountPropTypes {
    "A": number,
    "AA": number,
    "AAA": number,
}


interface disabilitiesCountPropTypes {
    "Cognitive & Learning": number,
    "Motor Impaired": number,
    "Blind": number,
    "Color Blind": number,
    "Dyslexia": number,
    "Seizure & Epileptic": number,
    "ADHD": number,
    "Elderly": number,
    "Visually Impaired": number,
}

// component to render accessibility violation list
const AccessibilityViolationsList = (prop: propType) => {

    const { t } = useTranslation();

    const { websiteURL } = useSelector((store: StoreProptypes) => store);

    const { violationsGroupList, fixedByWidget } = prop;



    const [showCategoryData, setShowCategoryData] = useState(false);

    const [showViolationData, setShowViolationData] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDisability, setSelectedDisability] = useState("All");
    const [selectedWCAGLevel, setSelectedWCAGLevel] = useState("All");

    const [filteredViolationsGroupList, setFilteredViolationsGroupList] = useState<groupDetailPropTypes[]>();

    const [selectedViolation, setSelectedViolation] = useState<groupDetailPropTypes>();


    // function to handle click on category
    const handleCategoryClick = (keyname: string) => {

        setSelectedCategory(keyname);
        setShowCategoryData(true);
    }


    // function to handle click on violation
    const handleViolationClick = (violation: groupDetailPropTypes) => {
        setSelectedViolation(violation);
        setShowViolationData(true);
    }

    const [disabilityCounter, setDisabilityCounter] = useState<disabilitiesCountPropTypes>();
    const [sortedDisabilityCounter, setSortedDisabilityCounter] = useState<[string, number][]>();

    const [levelCounter, setLevelCounter] = useState<levelCountPropTypes>();

    useEffect(() => {
        if (disabilityCounter) {
            const temp = Object.entries(disabilityCounter).sort(([, a], [, b]) => b - a);
            setSortedDisabilityCounter(temp);
        }
    }, [disabilityCounter])

    useEffect(() => {
        if (selectedCategory) {
            const violationArray = violationsGroupList[selectedCategory];

            
            if (violationArray.length) {
                const filteredArray = violationArray.filter(item => {
                    let flag = false;

                    console.log("selectedDisability", selectedDisability);

                    switch (selectedDisability) {
                        case "All":
                            flag = true;
                            break;
                        case "Cognitive & Learning":
                            item.cognitive_disabilities ? (flag = true) : (flag = false);
                            break;
                        case "Motor Impaired":
                            item.motor_impaired ? (flag = true) : (flag = false);
                            break;
                        case "Blind":
                            item.blind ? (flag = true) : (flag = false);
                            break;
                        case "Color Blind":
                            item.color_blind ? (flag = true) : (flag = false);
                            break;
                        case "Dyslexia":
                            console.log("Dyslexia detected")
                            item.dyslexia ? (flag = true) : (flag = false);
                            break;
                        case "Seizure & Epileptic":
                            item.seizure_epileptic ? (flag = true) : (flag = false);
                            break;
                        case "ADHD":
                            item.adhd ? (flag = true) : (flag = false);
                            break;
                        case "Elderly":
                            item.elderly ? (flag = true) : (flag = false);
                            break;
                        case "Visually Impaired":
                            item.visually_impaired ? (flag = true) : (flag = false);
                            break;
                        default:
                            flag = true;
                    }

                    if (flag) {
                        switch (selectedWCAGLevel) {
                            case "All":
                                flag = true;
                                break;
                            case "Level A":
                                console.log("Level A");
                                item.level_a ? (flag = true) : (flag = false);
                                break;
                            case "Level AA":
                                console.log("Level AA");
                                item.level_aa ? (flag = true) : (flag = false);
                                break;
                            case "Level AAA":
                                console.log("Level AAA");
                                item.level_aaa ? (flag = true) : (flag = false);
                                break;
                            default:
                                flag = true;

                        }
                    }



                    return flag;
                });

                
                setFilteredViolationsGroupList(filteredArray);
            }
        }

    }, [selectedDisability, selectedWCAGLevel, selectedCategory])

    

    useEffect(() => {
        if (selectedCategory) {
            setSelectedDisability('All');
            setSelectedWCAGLevel('All');

            let tempDisabilityCounter = {
                "Cognitive & Learning": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.cognitive_disabilities) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Motor Impaired": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.motor_impaired) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Blind": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.blind) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Color Blind": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.color_blind) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Dyslexia": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.dyslexia) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Seizure & Epileptic": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.seizure_epileptic) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "ADHD": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.adhd) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Elderly": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.elderly) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "Visually Impaired": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.visually_impaired) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
            }

            let tempLevelCounter = {
                "A": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.level_a) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "AA": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.level_aa) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
                "AAA": violationsGroupList[selectedCategory].reduce((accumulator, item) => {
                    if (item.level_aaa) {
                        return accumulator + 1
                    }
                    else return accumulator
                }, 0),
            }



            setDisabilityCounter(tempDisabilityCounter);
            setLevelCounter(tempLevelCounter);


        }
    }, [selectedCategory])



    return (
        <>

            
            <div className='violations-group-list'>

                {violationsGroupList ? (
                    <>
                        <h2 className='text-center h4'>{t("Click on the categories to check the detailed information.")}</h2>
                        <Row xs={1} sm={1} md={2} lg={3} xl={3} xxl={4}>
                            {Object.keys(violationsGroupList).map((keyname) => {
                                const successScore = violationsGroupList[keyname].reduce((accumulator, item) => {
                                    if (item.is_fixed_by_widget && fixedByWidget) {
                                        return accumulator + item.total_success + item.total_error;
                                    }
                                    else {
                                        return accumulator + item.total_success;
                                    }
                                }, 0);

                                const errorScore = violationsGroupList[keyname].reduce((accumulator, item) => {
                                    if (item.is_fixed_by_widget && fixedByWidget) {
                                        return accumulator + 0
                                    }
                                    else {
                                        return accumulator + item.total_error
                                    }
                                }, 0);

                                return (
                                    <React.Fragment key={keyname}>
                                        {violationsGroupList[keyname].length > 0 ? (
                                            <Col>
                                                <div className='violations-group-box violations-card' role={(successScore === 0 && errorScore === 0) ? 'none' : 'button'} tabIndex={0} onClick={() => {
                                                    !(successScore === 0 && errorScore === 0) && handleCategoryClick(keyname);
                                                }
                                                }>
                                                    <div className='violations-group-title'>
                                                        <h2 className='h4 mb-0'>{keyname}</h2>
                                                    </div>
                                                    <div className='violation-status-wrapper'>

                                                        {(successScore === 0 && errorScore === 0) ? <>
                                                            <div className='violation-status'>
                                                                <img src={NAIcon} alt="" />
                                                                {t("Not Applicable")}
                                                            </div>
                                                        </> : <>
                                                            <div className='violation-status'>
                                                                <img src={PassedIcon} alt="" />
                                                                <Trans
                                                                    i18nKey="X Passed"
                                                                    defaults="{{- number}} Passed"
                                                                    values={{
                                                                        number: successScore,
                                                                    }} />
                                                            </div>
                                                            <div className='violation-status'>
                                                                <img src={FailedIcon} alt="" />
                                                                <Trans
                                                                    i18nKey="X Failed"
                                                                    defaults="{{- number}} Failed"
                                                                    values={{
                                                                        number: errorScore,
                                                                    }} />
                                                            </div>
                                                        </>}




                                                    </div>

                                                </div>
                                            </Col>
                                        ) : (
                                            <>

                                            </>
                                        )}

                                    </React.Fragment>
                                )
                            })}
                        </Row>
                    </>
                ) : (
                    <></>
                )}

            </div>



            {selectedCategory ?
                <Offcanvas show={showCategoryData} onHide={() => { setShowCategoryData(false) }} className="category-details">
                    <Offcanvas.Header closeButton>
                        <div className='w-100'>
                            <div className='offcanvas-branding-wrapper'>
                                <div className='logo-wrapper'>
                                    <img src={logo} alt={t('logo-alt-text')} />
                                </div>
                                <p className='text-muted mb-0'>
                                    <Trans
                                        i18nKey="Accessibility report for"
                                        defaults="Accessibility report for <bold>{{- website}}</bold>"
                                        values={{
                                            website: websiteURL,
                                        }}
                                        components={{
                                            bold: <strong />,
                                        }}
                                    />
                                </p>
                            </div>
                            <Offcanvas.Title as="h2" className='h1'>{selectedCategory}</Offcanvas.Title>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="filter-section-wrapper">
                            <div className='wcag-filter-wrapper'>
                                <h3 className='h5'>{t("Filter by WCAG 2.0/2.1/2.2 Levels")}</h3>
                                <Nav variant="pills" defaultActiveKey="All" activeKey={selectedWCAGLevel}>
                                    <Nav.Item>
                                        <Nav.Link
                                            aria-pressed={selectedWCAGLevel === "All"}
                                            eventKey='All'
                                            onClick={() => setSelectedWCAGLevel("All")}>
                                            {t("All")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            aria-pressed={selectedWCAGLevel === "Level A"}
                                            eventKey="Level A"
                                            onClick={() => setSelectedWCAGLevel("Level A")}>
                                            {t("Level A")} {`(${levelCounter?.A})`}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            aria-pressed={selectedWCAGLevel === "Level AA"}
                                            eventKey="Level AA"
                                            onClick={() => setSelectedWCAGLevel("Level AA")}>
                                            {t("Level AA")} {`(${levelCounter?.AA})`}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            aria-pressed={selectedWCAGLevel === "Level AAA"}
                                            eventKey="Level AAA"
                                            onClick={() => setSelectedWCAGLevel("Level AAA")}>
                                            {t("Level AAA")} {`(${levelCounter?.AAA})`}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='disabilities-filter'>

                                <Form.Label className='h5' htmlFor="disabilities_filter">{t("Filter by: Disabilities Affected")}</Form.Label>
                                <Form.Select id="disabilities_filter" value={selectedDisability} onChange={(e) => setSelectedDisability(e.currentTarget.value)} disabled={!sortedDisabilityCounter}>
                                    <option value="All">{t("All")}</option>
                                    {sortedDisabilityCounter?.map((item) => {
                                        return <option value={item[0]}>{t(item[0])} {`(${item[1]})`} </option>
                                    })}
                                </Form.Select>

                            </div>
                        </div>

                        {filteredViolationsGroupList?.length ?
                            <Table className='violations-table'>
                                <thead>
                                    <tr>
                                        <th scope='column' style={{ width: '70%' }}>{t("Description")}</th>
                                        <th scope='column' style={{ width: '17%' }}>{t("WCAG 2.0, 2.1 & 2.2")}</th>
                                        <th scope='column' style={{ width: '13%' }}>{t("Status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {filteredViolationsGroupList.map((violation) => {
                                            return (
                                                <tr key={violation.id} >
                                                    <td>
                                                        <Button
                                                            variant='link'
                                                            onClick={() => {
                                                                !(!violation.total_success && !violation.total_error) && handleViolationClick(violation)
                                                            }}
                                                            disabled={(!violation.total_success && !violation.total_error)}
                                                            className={`violation-message p-0`}>
                                                            {violation.message_readable} {!(!violation.total_success && !violation.total_error) ? <>
                                                                <img src={LinkIcon} alt="" style={{ width: 17 }} />
                                                            </> : ''}
                                                        </Button>
                                                        <DisabilitiesAffected violation={violation} />
                                                    </td>
                                                    <td data-label={t("WCAG 2.0, 2.1 & 2.2 Level")}>
                                                        <ul className='wcag-level-list'>
                                                            {violation.level_a ? <li>A</li> : <></>}
                                                            {violation.level_aa ? <li>AA</li> : <></>}
                                                            {violation.level_aaa ? <li>AAA</li> : <></>}
                                                        </ul>
                                                    </td>
                                                    <td>

                                                        {(violation.is_fixed_by_widget && fixedByWidget) ? <>
                                                            <div className='violation-status total-success'>
                                                                <Trans
                                                                    i18nKey="X Successes"
                                                                    defaults="{{- number}} Successes"
                                                                    values={{ number: violation.total_success + violation.total_error }}
                                                                />
                                                                <OverlayTrigger
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={<Tooltip>
                                                                        <Trans
                                                                            i18nKey="Successes Description"
                                                                            defaults="{{- number}} element(s) passed the criteria"
                                                                            values={{ number: violation.total_success + violation.total_error }}
                                                                        />
                                                                    </Tooltip>}
                                                                >
                                                                    <img src={alertIcon} alt="" style={{ width: 20 }} />
                                                                </OverlayTrigger>
                                                            </div>

                                                        </>
                                                            : <>
                                                                {violation.total_success ?
                                                                    <div className='violation-status total-success'>
                                                                        <Trans
                                                                            i18nKey="X Successes"
                                                                            defaults="{{- number}} Successes"
                                                                            values={{ number: violation.total_success }}
                                                                        />
                                                                        <OverlayTrigger
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={<Tooltip>
                                                                                <Trans
                                                                                    i18nKey="Successes Description"
                                                                                    defaults="{{- number}} element(s) passed the criteria"
                                                                                    values={{ number: violation.total_success }}
                                                                                />
                                                                            </Tooltip>}>
                                                                            <img src={alertIcon} alt="" style={{ width: 20 }} />
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    : <></>}
                                                                {violation.total_error ?
                                                                    <div className='violation-status total-error'>
                                                                        <Trans
                                                                            i18nKey="X Failures"
                                                                            defaults="{{- number}} Failures"
                                                                            values={{ number: violation.total_error }}
                                                                        />
                                                                        <OverlayTrigger
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={<Tooltip>
                                                                                <Trans
                                                                                    i18nKey="Failures Description"
                                                                                    defaults="{{- number}} element(s) failed for the criteria"
                                                                                    values={{ number: violation.total_error }}
                                                                                />
                                                                            </Tooltip>}>
                                                                            <img src={alertIcon} alt="" style={{ width: 20 }} />
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    : <></>
                                                                }
                                                            </>
                                                        }



                                                        {(!violation.total_success && !violation.total_error) ?
                                                            <div className='violation-status not-applicable'>
                                                                {t("Not Applicable")}
                                                                <OverlayTrigger
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={<Tooltip>
                                                                        {t("Not Applicable Description")}
                                                                    </Tooltip>}>
                                                                    <img src={alertIcon} alt="" style={{ width: 20 }} />
                                                                </OverlayTrigger>
                                                            </div> :
                                                            <></>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                </tbody>
                            </Table>
                            : <>
                                <p className='h3 text-center mt-5'>{t("No Result Found")}</p>
                            </>
                        }

                        <div className='violations-group-list'>
                            <Container className='ms-0'>
                                {violationsGroupList ? (
                                    <>
                                        <h3 className='h4 mb-3'>{t("Other Categories")}</h3>
                                        <Row xs={1} sm={1} md={2} lg={3} xl={3} xxl={4}>
                                            {Object.keys(violationsGroupList).map((keyname) => {
                                                return (
                                                    <React.Fragment key={keyname}>
                                                        {(keyname !== selectedCategory) ? <>
                                                            {violationsGroupList[keyname].length > 0 ? (
                                                                <Col>
                                                                    <div className='violations-group-box violations-card' role="button" tabIndex={0} onClick={() => handleCategoryClick(keyname)}>
                                                                        <div className='violations-group-title'>
                                                                            <h2 className='h4 mb-0'>{keyname}</h2>
                                                                        </div>
                                                                        <div className='violation-status-wrapper'>
                                                                            <div className='violation-status'>
                                                                                <img src={PassedIcon} alt="" />
                                                                                <Trans
                                                                                    i18nKey="X Passed"
                                                                                    defaults="{{- number}} Passed"
                                                                                    values={{
                                                                                        number: violationsGroupList[keyname].reduce((accumulator, item) => {
                                                                                            if (item.is_fixed_by_widget && fixedByWidget) {
                                                                                                return accumulator + item.total_success + item.total_error;
                                                                                            }
                                                                                            else {
                                                                                                return accumulator + item.total_success;
                                                                                            }
                                                                                        }, 0),
                                                                                    }} />
                                                                            </div>
                                                                            <div className='violation-status'>
                                                                                <img src={FailedIcon} alt="" />
                                                                                <Trans
                                                                                    i18nKey="X Failed"
                                                                                    defaults="{{- number}} Failed"
                                                                                    values={{
                                                                                        number: violationsGroupList[keyname].reduce((accumulator, item) => {
                                                                                            if (item.is_fixed_by_widget && fixedByWidget) {
                                                                                                return accumulator + 0
                                                                                            }
                                                                                            else {
                                                                                                return accumulator + item.total_error
                                                                                            }
                                                                                        }, 0),
                                                                                    }} />
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </> : <>
                                                        </>}

                                                    </React.Fragment>
                                                )
                                            })}
                                        </Row>
                                    </>

                                ) : (
                                    <>

                                    </>
                                )}
                            </Container>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>
                : <></>
            }

            {selectedViolation ?
                <Offcanvas show={showViolationData} onHide={() => setShowViolationData(false)} className="violation-details" backdropClassName="violation-details-backdrop">
                    <Offcanvas.Header closeButton>
                        <div className='w-100'>
                            <div className='offcanvas-branding-wrapper'>
                                <div className='logo-wrapper'>
                                    <img src={logo} alt={t('logo-alt-text')} />
                                </div>
                                <p className='text-muted mb-0'>
                                    <Trans
                                        i18nKey="Accessibility report for"
                                        defaults="Accessibility report for <bold>{{- website}}</bold>"
                                        values={{
                                            website: websiteURL,
                                        }}
                                        components={{
                                            bold: <strong />,
                                        }}
                                    />
                                </p>
                            </div>
                            <div>
                                <Offcanvas.Title as="h2" className='h1'>{selectedViolation.message_readable}</Offcanvas.Title>
                                <p>{selectedViolation.requirement}</p>
                            </div>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='violation-meta-wrapper'>
                            <DisabilitiesAffected violation={selectedViolation} />
                            <div className='wcag-criteria-wrapper'>
                                <strong role="heading"> {t("WCAG 2.0, 2.1 & 2.2")} </strong>
                                <ul>
                                    {selectedViolation.level_messages_a.length ? selectedViolation.level_messages_a.map((message, index) => {
                                        return <li key={`levelA${index}`}> <span className='level'>A</span> <span className='message'>{message}</span> </li>
                                    }) : <></>}
                                    {selectedViolation.level_messages_aa.length ? selectedViolation.level_messages_aa.map((message, index) => {
                                        return <li key={`levelAA${index}`}> <span className='level'>AA</span> <span className='message'>{message}</span> </li>
                                    }) : <></>}
                                    {selectedViolation.level_messages_aaa.length ? selectedViolation.level_messages_aaa.map((message, index) => {
                                        return <li key={`levelAAA${index}`}> <span className='level'>AAA</span> <span className='message'>{message}</span> </li>
                                    }) : <></>}
                                </ul>
                            </div>
                        </div>
                        <Table className='violations-table'>
                            <thead>
                                <tr>
                                    <th scope='column' style={{ width: 95 }}>{t("Index")}</th>
                                    <th scope='column'>{t("Elements")}</th>
                                    <th scope='column' style={{ width: 120 }}>{t("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>

                                {selectedViolation.total_error > 0 ? (
                                    <>
                                        {selectedViolation.error_details && selectedViolation.error_details.length > 0 && (
                                            <>
                                                <ElementsListMain
                                                    element={selectedViolation.error_details}
                                                    violationType={(selectedViolation.is_fixed_by_widget && fixedByWidget) ? 'success' : 'error'}
                                                    startIndex={1}
                                                />
                                            </>
                                        )}

                                    </>
                                ) : (
                                    <></>
                                )}

                                {selectedViolation.total_success > 0 ? (
                                    <>
                                        {selectedViolation.success_details && selectedViolation.success_details.length > 0 && (
                                            <>
                                                <ElementsListMain element={selectedViolation.success_details} violationType='success' startIndex={selectedViolation.error_details ? selectedViolation.error_details.length + 1 : 1} />
                                            </>
                                        )}

                                    </>
                                ) : (
                                    <>

                                    </>
                                )}


                            </tbody>
                        </Table>

                    </Offcanvas.Body>
                </Offcanvas>

                : <></>

            }



        </>
    )
}

export default AccessibilityViolationsList;