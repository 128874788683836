import MetaDataProivder from '../../Components/MetaDataProvider';
import MainTemplate from '../../Template/MainTemplate';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { toPng } from 'html-to-image';

import heroImage from "../../Assets/img/accessibility-checker-hero.svg";

import ScanURLFormComponents from '../../Components/ScanURLForm';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { ScanningProcessService } from '../../Services/ScanningProcessService';
import { Link, useSearchParams } from 'react-router-dom';
import WebsiteURLSection from '../../Components/ScanDetails/WebsiteURLSecrion';

import AccessibilityViolationsList from '../../Components/ScanDetails/AccessibilityViolationsList';

import { groupDataMainPropTypes, requestDataProptype } from '../../PropTypes/ScanningScreenPropType';
import useWindowSize from '../../Helpers/useWindowSize';


//import ViolationsGroupListPlaceholder from '../../Components/Placeholder/ViolationsGroupList';
import ReactGA from "react-ga4";


import CustomLoader from '../../Components/CustomLoader';
import CustomHeader from '../../Components/CustomHeader';
import Footer from '../../Components/Footer';
import logo from "../../Assets/img/accessibility-checker-logo.svg";
import errorImage from "../../Assets/img/error-image.svg";
import DownloadReport from '../../Components/DownloadReport';
import AllInOneAccessibilityCTA from '../../Components/AllInOneAccessibilityCTA';
import { WEBSITE_URL } from '../../Actions/Types';



interface tempGropScorePropType {
  [keyname: string]: number[];
}
interface finalGropTotalScorePropType {
  [keyname: string]: number;
}


const Home = () => {

  ReactGA.initialize("G-85C9EMDGMJ", {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  });

  const { scaningProcess, scaningProcessPer, websiteURL, reScaningProcess, isLoading, isPaidFree } = useSelector((store: StoreProptypes) => store);

  const dispatch = useDispatch();

  const { windowWidth } = useWindowSize();


  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const websiteURLParam = searchParams.get("website");

  useEffect(() => {
    if (websiteURLParam) {
      dispatch({ type: WEBSITE_URL, payload: websiteURLParam.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '') })
      //ScanningProcessService((), 2, i18n.language, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
      //console.log("API Call websiteURLParam ==>", websiteURLParam)
    }
  }, [websiteURLParam]);

  useEffect(() => {
    if (websiteURL) {
      setScreenError(false)
      ScanningProcessService(websiteURL, 2, i18n.language, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
    }

  }, [websiteURL])

  // Scan Result

  const [apiRecall, setApiRecall] = useState(false);

  const [fixedByWidget, setFixedByWidget] = useState(false);

  const [violationsGroupList, setViolationsGroupList] = useState<groupDataMainPropTypes>();

  const [filteredviolationsGroupList, setFilteredViolationsGroupList] = useState<groupDataMainPropTypes>();
  const [requestDataObj, setRequestDataObj] = useState<requestDataProptype>();

  const [errorViolationsTotal, setErrorViolationsTotal] = useState<number>(0);
  const [successViolationsTotal, setSuccessViolationsTotal] = useState<number>(0);
  const [errorViolationsTotalFixedByWidget, setErrorViolationsTotalFixedByWidget] = useState<number>(0);

  const [accessibilitySystemDetected, setAccessibilitySystemDetected] = useState<string>('');

  const [screenError, setScreenError] = useState<boolean>(false);



  useEffect(() => {
    if (violationsGroupList) {
      setFilteredViolationsGroupList(violationsGroupList);
    }

  }, [violationsGroupList])





  const demoFunction = () => {
    /* console.log("demo function");
    console.log("scaningProcess", scaningProcess);
    console.log("websiteURL", websiteURL);
    console.log("scaningProcessPer", scaningProcessPer); */
    if (scaningProcess && websiteURL && scaningProcessPer !== 100) {
      ScanningProcessService(websiteURL, 1, i18n.language, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
    }
  }

  useEffect(() => {
    if (reScaningProcess && !scaningProcess) {
      setApiRecall(false);
    }
  }, [reScaningProcess, scaningProcess])

  useEffect(() => {
    if (apiRecall) {
      /* console.log("apiRecall use effect"); */
      setApiRecall(false);
      setTimeout(demoFunction, 2000);
    }
  }, [apiRecall])



  // Accessibility Score

  const [accessibilityScore, setAccessibilityScore] = useState<number | null>(null);
  const [groupAccessibilityScore, setGroupAccessibilityScore] = useState<tempGropScorePropType>();
  const [finalGroupAccessibilityScore, setFinalGroupAccessibilityScore] = useState<finalGropTotalScorePropType>();

  useEffect(() => {
    if (violationsGroupList) {
      let keyObjScore: tempGropScorePropType = {};
      Object.keys(violationsGroupList).map((keyname) => {
        violationsGroupList[keyname].map((violation) => {
          let tempScore;
          if (fixedByWidget && violation.is_fixed_by_widget) {
            tempScore = 100;
            const tempObjValue = keyObjScore[keyname] ? { [keyname]: [...keyObjScore[keyname], tempScore] } : { [keyname]: [tempScore] }
            keyObjScore = { ...keyObjScore, ...tempObjValue }
          }
          else {
            if (violation.total_error > 0 || violation.total_success > 0) {
              if (violation.total_success > 0) {
                tempScore = parseInt(((violation.total_success / (violation.total_success + violation.total_error)) * 100).toFixed(2));
              } else {
                tempScore = 0;
              }
              const tempObjValue = keyObjScore[keyname] ? { [keyname]: [...keyObjScore[keyname], tempScore] } : { [keyname]: [tempScore] }
              keyObjScore = { ...keyObjScore, ...tempObjValue }
            }
            /*  else{ 
               tempScore = 100;
             } */

          }
        })
      })
      setGroupAccessibilityScore(keyObjScore);
    }
  }, [violationsGroupList, fixedByWidget])


  // Grop Score
  useEffect(() => {
    if (groupAccessibilityScore) {
      console.log("groupAccessibilityScore", groupAccessibilityScore);
      let tempObj: finalGropTotalScorePropType = {}
      Object.keys(groupAccessibilityScore).map((keyname, gIndex) => {
        const sum = groupAccessibilityScore[keyname].reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }, 0);
        const persentage = sum / groupAccessibilityScore[keyname].length;
        tempObj = { ...tempObj, [keyname]: persentage }
      })
      setFinalGroupAccessibilityScore(tempObj);
    }
  }, [groupAccessibilityScore])


  useEffect(() => {
    if (finalGroupAccessibilityScore) {
      console.log("finalGroupAccessibilityScore", finalGroupAccessibilityScore);
      let sum = 0;
      Object.keys(finalGroupAccessibilityScore).map((keyname, gIndex) => {
        sum = sum + finalGroupAccessibilityScore[keyname];
      })
      const persentage = sum / Object.keys(finalGroupAccessibilityScore).length;
      setAccessibilityScore(persentage);
    }
  }, [finalGroupAccessibilityScore]);



  /* useEffect(() => {
    if (errorViolationsTotal > 0 && successViolationsTotal > 0) {
      let score = 0;
      if (fixedByWidget) {
        score = (successViolationsTotal + errorViolationsTotalFixedByWidget) / (errorViolationsTotal + successViolationsTotal) * 100;
      }
      else {
        score = successViolationsTotal / (errorViolationsTotal + successViolationsTotal) * 100;
      }
      setAccessibilityScore(score);
    }
    else if (errorViolationsTotal === 0 && successViolationsTotal > 0) {
      setAccessibilityScore(100);
    }
    else if (errorViolationsTotal > 0 && successViolationsTotal === 0) {
      setAccessibilityScore(0);
    }

  }, [successViolationsTotal, errorViolationsTotal, errorViolationsTotalFixedByWidget, fixedByWidget]) */


  interface ForwardedRefs {
    scoreRef: React.RefObject<HTMLDivElement>;
    wcagRef: React.RefObject<HTMLDivElement>;
  }

  const forwardedRefs = useRef<ForwardedRefs>({
    scoreRef: useRef<HTMLDivElement>(null),
    wcagRef: useRef<HTMLDivElement>(null),
  });

  const [scoreImage, setScoreImage] = useState<string>('');
  const [wcagImage, setWCAGImage] = useState<string>('');



  const GetScreenshot = () => {
    console.log("GetScreenshot called");
    console.log("ref.current", forwardedRefs.current.scoreRef.current);
    if (forwardedRefs.current.scoreRef.current === null || forwardedRefs.current.wcagRef.current === null) {
      return
    }


    toPng(forwardedRefs.current.scoreRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        /* const link = document.createElement('a')
        link.download = 'my-image-name.png'
        link.href = dataUrl
        link.click() */
        setScoreImage(dataUrl);
      })
      .catch((err) => {
        console.log(err)
      });

    toPng(forwardedRefs.current.wcagRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        /* const link = document.createElement('a')
        link.download = 'my-image-name1.png'
        link.href = dataUrl
        link.click() */
        setWCAGImage(dataUrl);
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (violationsGroupList && websiteURL) {
      ScanningProcessService(websiteURL, 1, i18n.language, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
    }
  }, [i18n.language])


  //const [ipAddress, setIpAddress] = useState('');
  //const [countryCode, setCountryCode] = useState('');

  /* useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const ipData = response.data;
        setIpAddress(ipData.ip);
        //setCountryCode(ipData.country_code);
      })
      .catch(error => console.error(error));
  }, []); */

  return (
    <>

      <MainTemplate>

        <MetaDataProivder />


        <div className={`page-content show-lang`}>
        {/* <div className={`page-content ${ipAddress === '59.97.182.246' ? 'show-lang' : ''}`}> */}

          {screenError ? (
            <>
              <div className="error-page-wrapper">
                <Container>
                  <div className='logo-wrapper'>
                    <img src={logo} alt={t('logo-alt-text')} />
                  </div>
                  <div className='graphics-form-wrapper'>
                    <div className='error-image-wrapper'>
                      <img src={errorImage} alt="" />
                    </div>
                    <div className='error-text-form-wrapper text-center'>
                      <h2 className='h1'>{t("Website Connection Error")}</h2>
                      <p>
                        <Trans components={{ br: <br />, a: <a /> }}>{t('Connection Error Message')}</Trans>
                      </p>
                      <ScanURLFormComponents />
                    </div>
                  </div>
                </Container>
                <Container className='report-a-problem-link'>
                  <Link to="/report-a-problem">{t("Report a Problem")}</Link>
                </Container>
              </div>
            </>
          ) : (
            <>
              {!scaningProcess && !reScaningProcess ? (
                <>
                  <Container fluid className="landing-page-wrapper">
                    <Row className="h-100">

                      {windowWidth > 1560 ?
                        <Col className="hero-image-wrapper d-flex align-items-center justify-content-center p-5">
                          <img src={heroImage} alt={t("logo-alt-text")} />
                        </Col>
                        : <></>
                      }

                      <Col className={`${windowWidth > 1560 ? 'd-flex' : ''}`}>
                        <div className="left-wrapper">
                          <CustomHeader />
                          <Container className="form-wrapper">

                            <h1>
                              <Trans i18nKey='landing-headline'>
                                Our free <span>ADA</span> and <span>WCAG compliance</span> checker identifies web accessibility issues
                              </Trans>
                            </h1>
                            <ScanURLFormComponents />


                          </Container>
                          <Footer />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              ) : (
                <>

                  {filteredviolationsGroupList && (accessibilityScore !== null) && !isLoading ? (
                    <>
                      <div className="page-content-inner">
                        {fixedByWidget ? <div className="star-background">
                          <div className="flash"></div>
                        </div>
                          : <></>
                        }

                        <div className='scan-details-page-main'>
                          <CustomHeader />
                          <div className='light-bg pb-4 pb-lg-5'>
                            {websiteURL ? <>
                              <div className='report-url-pdf-section'>
                                <Container>
                                  <div className='url'>
                                    <Trans
                                      i18nKey="Accessibility report for"
                                      defaults="Accessibility report for <bold>{{- website}}</bold>"
                                      values={{
                                        website: websiteURL,
                                      }}
                                      components={{
                                        bold: <strong />,
                                      }}
                                    />
                                  </div>
                                  {requestDataObj ? <DownloadReport requestDataObj={requestDataObj} GetScreenshot={GetScreenshot} scoreImage={scoreImage} wcagImage={wcagImage} /> : <></>}
                                </Container>
                              </div>
                            </> : <></>}

                            {requestDataObj ? <>

                            </> : <></>}
                            {(accessibilityScore !== null) ? <>
                              <WebsiteURLSection
                                accessibilitySystemDetected={accessibilitySystemDetected}
                                accessibilityScore={accessibilityScore}
                                errorViolationsTotal={errorViolationsTotal}
                                errorViolationsTotalFixedByWidget={errorViolationsTotalFixedByWidget}
                                fixedByWidget={fixedByWidget}
                                setFixedByWidget={setFixedByWidget}
                                ref={forwardedRefs}

                              />
                            </> : <></>}


                          </div>
                          <Container>

                            {requestDataObj ?
                              <AccessibilityViolationsList
                                fixedByWidget={fixedByWidget}
                                violationsGroupList={filteredviolationsGroupList}
                              />
                              : <></>
                            }

                            <div className='site-footer'>

                              {(accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected !== "All in One Accessibility™") ?
                                <AllInOneAccessibilityCTA />
                                : (
                                  <>
                                    {isPaidFree ? (
                                      <>
                                        {isPaidFree === 2 ? <>

                                        </> : <>
                                          <AllInOneAccessibilityCTA />
                                        </>}

                                      </>
                                    ) : (
                                      <>
                                        <AllInOneAccessibilityCTA />
                                      </>
                                    )}
                                  </>
                                )}



                              <div className="cts-box">
                                <div className="cts-box-text">
                                  <h2 className="h3 mb-3">{t("Manual Accessibility Audit Title")}</h2>
                                  <p>{t("Manual Accessibility Audit Description")}</p>
                                </div>
                                <div className="cts-box-btn">
                                  <a href="https://www.skynettechnologies.com/website-accessibility-audit" className="btn btn-light" target='_blank'>
                                    <span>{t("Request Manual Accessibility Audit")}</span>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                      <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>

                            </div>

                          </Container>

                          <Footer />
                        </div>
                      </div>

                    </>
                  ) : (
                    <>
                      <CustomLoader />
                    </>
                  )}



                </>
              )}
            </>
          )}

        </div>

      </MainTemplate >
    </>
  )

}

export default Home;