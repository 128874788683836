import ErrorPage from '../Pages/ErrorPage';

// Pages
import Home from '../Pages/Home';
import PrivacyPolicyPage from '../Pages/PrivacyPolicy';
import ReportProblemPage from '../Pages/ReportProbem';
import ScoreLimitationsPage from '../Pages/ScoreLimitations';
import TermsConditionsPage from '../Pages/TermsConditions';

// routes list
export const routesList = [
    {
        path: "/",
        element: <Home />,
        breadcrumb: "Dashboard",
        protected: true,
        metaData: {
            title: "Website Accessibility Checker - Scan Accessibility Compliance",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    }, {
        path: "/404",
        element: <ErrorPage />,
        breadcrumb: "404 Error",
        protected: false,
        metaData: {
            title: "404 Error | Accessibility Checker",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
        breadcrumb: "Privacy Policy",
        protected: false,
        metaData: {
            title: "Privacy Policy | Accessibility Checker",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    },
    {
        path: "/terms-and-conditions",
        element: <TermsConditionsPage />,
        breadcrumb: "Terms and Conditions",
        protected: false,
        metaData: {
            title: "Terms and Conditions | Accessibility Checker",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    },
    {
        path: "/report-a-problem",
        element: <ReportProblemPage />,
        breadcrumb: "Report a Problem",
        protected: false,
        metaData: {
            title: "Terms and Conditions | Accessibility Checker",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    },
    {
        path: "/automated-accessibility-score-limitations",
        element: <ScoreLimitationsPage />,
        breadcrumb: "Automated Accessibility Score Limitations",
        protected: false,
        metaData: {
            title: "Automated Accessibility Score Limitations | Accessibility Checker",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    }
]